import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import React, { useState, useEffect } from "react";
import Select, { ActionMeta, SingleValue } from "react-select";
import api from "@beeldit/core/helpers/api";
import { Report } from "../models/Report";
import index from "../services/useIndex";

interface Option {
  label: string;
  value: number | string;
}

function CustomSelectWidget(props: any) {
  const { id, value, required, disabled, readonly, onChange } = props;
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [options, setOptions] = useState<Option[]>([]);
  const [elementsData, setElementsData] = useState({ elements: [] });

  const errorHandler = useErrorHandler();
  const index = () => {
    api
      .get(`/reports`)
      .then((response) => {
        setElementsData(response.data);
      })
      .catch((error) => {
        errorHandler(error, null);
      });
  };

  useEffect(() => {
    index();
  }, []);

  useEffect(() => {
    const options = elementsData.elements.map((report: Report) => {
      return { label: report.name, value: report.id };
    });
    setOptions(options);
    const currentSelectedOption = options.find(
      (option) => option.value === value
    );
    setSelectedOption(currentSelectedOption || null);
  }, [elementsData, value]);

  const handleOnChange = (
    newValue: SingleValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    const selectedOption = newValue as Option | null;
    setSelectedOption(selectedOption);
    const model = selectedOption?.value ?? null;
    onChange(model);
  };

  const selectStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: "lightgray",
      borderRadius: 0,
    }),
  };

  return (
    <Select
      id={id}
      options={options}
      value={selectedOption}
      required={required}
      isDisabled={disabled || readonly}
      onChange={handleOnChange}
      styles={selectStyles}
      placeholder="Todos los reportes"
      isClearable={true}
    />
  );
}

function ReportSelector(props: any) {
  const { id, options, value, required, disabled, readonly, onChange } = props;
  return (
    <CustomSelectWidget
      id={id}
      options={options.enumOptions}
      value={value}
      required={required}
      disabled={disabled}
      readonly={readonly}
      onChange={onChange}
    />
  );
}

export default ReportSelector;
