import { t } from "i18next";
import { MouseEventHandler, useContext } from "react";
import { Button, Modal, Table } from "react-bootstrap";

interface Context {
  showAddTimeFormModal: boolean;
  setShowAddTimeFormModal: (showModal: boolean) => MouseEventHandler;
}
interface ModalConfig {
  title: string;
  context: any;
  onSubmit: any;  
}

interface Props {
  modalConfig: ModalConfig;
  children: React.ReactNode;  
}

function AddTimeModal(props: Props) {
  const { title, context, onSubmit } = props.modalConfig;
  const { children } = props;
  const { showAddTimeFormModal: showModal, setShowAddTimeFormModal: setShowModal } = useContext<Context>(context);
  const handleClose = () => setShowModal(false); // We pass the function to close the modal on success  
  const onSubmitFunction = () => {
    onSubmit();
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title as="div">{t(title)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>
            Close
          </Button>
          <Button variant="outline-primary" onClick={onSubmitFunction}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddTimeModal;
