import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import customerIndex from "../../customers/services";
// import entryTimeIndex from "../../entry-time/services";
import { Customer } from "../../entry-time/models/Customer";
import { Report } from "../models/Report";
import React from "react";
import moment from "moment";
import { EntryTime } from "../../entry-time/models/EntryTime";
import { Col, Row } from "react-bootstrap";
import { ReportContext } from "../contexts/ReportContext";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import checkRoles from "@beeldit/user-and-access/auth/services/checkRoles";
import { User } from "@beeldit/user-and-access/users/models/User";
import get from "@beeldit/core/services/get";
import index from "@beeldit/core/services";

interface Day {
  day: number;
  date: string;
}

interface WeekTotals {
  [key: number]: { [key: number]: number };
}

function ReportPage() {
  /**
   * API Functions
   */

  /** element state definition */
  const { element, setElement } = useContext(ReportContext);
  const [customers, setCustomers] = useState({ elements: null, total: 0 });
  const [entryTimes, setEntryTimes] = useState({ elements: null, total: 0 });

  const { id } = useParams();

  const { user } = useContext(AuthContext);

  const errorHandler = useErrorHandler();

  useEffect(() => {
    if (id) {
      get("reports", parseInt(id))
        .then((response: any) => {
          setElement(response.data);
        })
        .catch((error: any) => {
          errorHandler(error, null);
        });
    }
    customerIndex(setCustomers, null);
    index("entry_times", `relations=fee&report_id=${id}`)
      .then((response: any) => {
        setEntryTimes(response.data);
      })
      .catch((error: any) => {
        errorHandler(error, null);
      });
  }, [id]);

  return (
    <>
      {element &&
      element.config &&
      customers &&
      customers.elements &&
      entryTimes &&
      entryTimes.elements ? (
        <pre>
          {monthDaysTable(
            element.month,
            element.year,
            customers.elements,
            entryTimes.elements,
            element.config.hours / element.config.days,
            element.config.average_price,
            element.config.holidays,
            user
          )}
        </pre>
      ) : null}
      <Row>
        {element && element.config && checkRoles(user, ["admin"]) ? (
          <>{renderEstimatedData(element.config)}</>
        ) : null}
        {element &&
        element.config &&
        entryTimes &&
        entryTimes.elements &&
        checkRoles(user, ["admin"]) ? (
          <>{renderRealData(element.config, entryTimes.elements)}</>
        ) : null}
      </Row>
    </>
  );
}

function renderEstimatedData(config: any) {
  return (
    <Col>
      <table className="table table-bordered ">
        <thead className="table-dark">
          <tr>
            <th colSpan={2} className="text-center">
              Estimado
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border-right">
              <strong>Horas</strong>
            </td>
            <td>{config.hours} Horas</td>
          </tr>
          <tr>
            <td className="border-right">
              <strong>Precio medio</strong>
            </td>
            <td>{config.average_price} Euros</td>
          </tr>
          <tr>
            <td className="border-right">
              <strong>Facturación estimada</strong>
            </td>
            <td>{config.average_price * config.hours} Euros</td>
          </tr>
          <tr>
            <td className="border-right">
              <strong>Facturación sin gastos</strong>
            </td>
            <td>{invoicedWithoutBills(config).toFixed(2)} Euros</td>
          </tr>
          <tr>
            <td className="border-right">
              <strong>Facturación sin irpf</strong>
            </td>
            <td>{invoicedWithoutTax(config).toFixed(2)} Euros</td>
          </tr>
          <tr>
            <td className="border-right">
              <strong>Facturado quitando sueldo</strong>
            </td>
            <td>{invoicedWithoutSalary(config).toFixed(2)}</td>
          </tr>
          <tr>
            <td className="border-right">
              <strong>Facturado quitando vacaciones</strong>
            </td>
            <td>{invoicedWithoutVacation(config).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </Col>
  );
}
function renderRealData(config: any, entryTimes: EntryTime[]) {
  const total = entryTimes.reduce(
    (total, entryTime) =>
      total +
      (entryTime.minutes / 60) *
        (entryTime.fee?.price ? entryTime.fee?.price : 0),
    0
  );
  return (
    <Col>
      <table className="table table-bordered">
        <thead className="table-dark">
          <tr>
            <th colSpan={2} className="text-center">
              Real
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border-right">
              <strong>Horas</strong>
            </td>
            <td>{config.hours} Horas</td>
          </tr>
          <tr>
            <td className="border-right">
              <strong>Precio medio</strong>
            </td>
            <td>{config.average_price} Euros</td>
          </tr>
          <tr>
            <td className="border-right">
              <strong>Facturación Real</strong>
            </td>
            <td>{total.toFixed(2)} Euros</td>
          </tr>
          <tr>
            <td className="border-right">
              <strong>Facturación sin gastos</strong>
            </td>
            <td>{realWithoutBills(config, total).toFixed(2)} Euros</td>
          </tr>
          <tr>
            <td className="border-right">
              <strong>Facturación sin irpf</strong>
            </td>
            <td>{realWithoutTax(config, total).toFixed(2)} Euros</td>
          </tr>
          <tr>
            <td className="border-right">
              <strong>Facturado quitando sueldo</strong>
            </td>
            <td>{realWithoutSalary(config, total).toFixed(2)}</td>
          </tr>
          <tr>
            <td className="border-right">
              <strong>Facturado quitando vacaciones</strong>
            </td>
            <td>{realWithoutVacation(config, total).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </Col>
  );
}

function invoicedWithoutBills(config: any) {
  return config.average_price * config.hours - bills(config.bills);
}
function invoicedWithoutTax(config: any) {
  return (
    invoicedWithoutBills(config) - tax(invoicedWithoutBills(config), config.tax)
  );
}
function invoicedWithoutSalary(config: any) {
  return invoicedWithoutTax(config) - config.salary;
}
function invoicedWithoutVacation(config: any) {
  return invoicedWithoutSalary(config) - config.vacation;
}

function realWithoutBills(config: any, total: number) {
  return total - bills(config.bills);
}
function realWithoutTax(config: any, total: number) {
  return (
    realWithoutBills(config, total) -
    tax(realWithoutBills(config, total), config.tax)
  );
}
function realWithoutSalary(config: any, total: number) {
  return realWithoutTax(config, total) - config.salary;
}
function realWithoutVacation(config: any, total: number) {
  return realWithoutSalary(config, total) - config.vacation;
}

function bills(bills: any[]) {
  let total = 0;
  bills.forEach((bill) => {
    total += bill.amount;
  });
  return total;
}

function tax(amount: number, irpf: number) {
  return (amount * irpf) / 100;
}

function monthDaysTable(
  month: any,
  year: any,
  customers: Customer[],
  entryTimes: any[],
  hoursPerDay: number,
  averagePrice: any,
  holidays: string[],
  user: User | null
) {
  const days: { day: number; date: string }[] = getMonthDays(month, year);
  const weeks: any[] = [[]];

  let currentWeekIndex = 0;
  days.forEach((day, index) => {
    const date = moment(day.date, "DD-MM-YYYY");
    const dayOfWeek = date.weekday();

    // Ignorar fines de semana
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return;
    }

    // Si es lunes y no es el primer día del mes, comenzar una nueva semana
    if (dayOfWeek === 1 && index > 0) {
      currentWeekIndex++;
      weeks.push([]);
    }
    weeks[currentWeekIndex].push(day);
  });

  const entriesByDateAndCustomer =
    transformEntryTimesByDateAndCustomer(entryTimes);
  const weekTotals = calculateWeekTotals(
    weeks,
    customers,
    entriesByDateAndCustomer
  );

  return (
    <table className="table table-light table-bordered">
      <thead className="table-dark">
        <tr>
          <th>Fecha</th>
          {customers.map((customer, index) => (
            <th key={index}>{customer.name}</th>
          ))}
          {checkRoles(user, ["admin"]) && (
            <>
              <th>Total</th>
              <th>Horas a trabajar</th>
              <th>Diferencia</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {weeks.map((week, weekIndex) => {
          let weeklyTotal = 0;
          let weeklyBilling = 0;
          const workingDays = week.filter(
            (day: any) =>
              moment(day.date, "DD-MM-YYYY").weekday() !== 0 &&
              moment(day.date, "DD-MM-YYYY").weekday() !== 6 &&
              !holidays.includes(
                moment(day.date, "DD-MM-YYYY").format("YYYY-MM-DD")
              )
          ).length;
          const workingHours = workingDays * hoursPerDay;
          const totalBilling = workingHours * averagePrice;
          const weekWithoutDays = week.length == 0;
          return (
            <React.Fragment key={weekIndex}>
              <tr className={weekWithoutDays ? "d-none" : "table-secondary"}>
                <td style={{ fontWeight: "bold" }}>Semana {weekIndex + 1}</td>
                {customers.map((customer, index) => {
                  const customerId = customer.id;
                  const total = weekTotals[weekIndex][customerId] || 0;
                  weeklyTotal += total;
                  weeklyBilling +=
                    (total / 60) *
                    (entryTimes.find(
                      (entry: any) => entry.customer_id === customerId
                    )?.fee.price || 0);
                  return <td key={index}>{(total / 60).toFixed(2)}</td>;
                })}
                {checkRoles(user, ["admin"]) && (
                  <>
                    <td>{(weeklyTotal / 60).toFixed(2)}</td>
                    <td>{workingHours.toFixed(2)}</td>
                    <td>
                      {totalBilling.toFixed(2)} - {weeklyBilling.toFixed(2)} ={" "}
                      {(totalBilling - weeklyBilling).toFixed(2)}
                    </td>
                  </>
                )}
              </tr>
              {week.map((day: any, dayIndex: any) => {
                const date = moment(day.date, "DD-MM-YYYY").format(
                  "DD-MM-YYYY"
                );
                const formattedDate = moment(day.date, "DD-MM-YYYY").format(
                  "YYYY-MM-DD"
                );
                const isNonWorkingDay = holidays.includes(formattedDate);
                const rowClass = isNonWorkingDay ? "d-none" : "";
                let dailyTotal = 0;
                return (
                  <tr key={`${weekIndex}-${dayIndex}`} className={rowClass}>
                    <td>
                      <strong>{day.date}</strong>
                    </td>
                    {customers.map((customer, index) => {
                      const customerId = customer.id;
                      const minutes =
                        entriesByDateAndCustomer[date]?.[customerId] || 0;
                      dailyTotal += minutes;
                      return <td key={index}>{(minutes / 60).toFixed(2)}</td>;
                    })}
                    {checkRoles(user, ["admin"]) && (
                      <>
                        <td>{(dailyTotal / 60).toFixed(2)}</td>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </tr>
                );
              })}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

function getMonthDays(month: number, year: number): Day[] {
  const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
  const days = [];

  for (let i = 1; i <= daysInMonth; i++) {
    const date = moment(`${i}-${month}-${year}`, "D-M-YYYY").format(
      "DD-MM-YYYY"
    );
    days.push({ day: i, date: date });
  }

  return days;
}

function transformEntryTimesByDateAndCustomer(entryTimes: EntryTime[]): any {
  const result: any = {};

  entryTimes.forEach((entryTime) => {
    const date = moment(entryTime.date).format("DD-MM-YYYY");
    const customerId = entryTime.customer_id;
    if (!result[date]) {
      result[date] = {};
    }
    if (!result[date][customerId]) {
      result[date][customerId] = 0;
    }
    result[date][customerId] += entryTime.minutes;
  });

  return result;
}

function calculateWeekTotals(
  weeks: any[],
  customers: Customer[],
  entriesByDateAndCustomer: any
) {
  return weeks.map((week) => {
    const weekTotals: { [customerId: number]: number } = {};

    customers.forEach((customer) => {
      weekTotals[customer.id] = 0;
    });

    week.forEach((day: any) => {
      const date = moment(day.date, "DD-MM-YYYY").format("DD-MM-YYYY");
      customers.forEach((customer) => {
        const customerId = customer.id;
        const minutes = entriesByDateAndCustomer[date]?.[customerId] || 0;
        weekTotals[customerId] += minutes;
      });
    });

    return weekTotals;
  });
}

export default ReportPage;
