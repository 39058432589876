import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { useContext } from "react";
import api from "@beeldit/core/helpers/api";
import { EntryTimeContext } from "../contexts/EntryTimeContext";

function useStore() {
  const { element, setBackendFormErrors } = useContext(EntryTimeContext);
  const errorHandler = useErrorHandler();
  return (onSuccess: any) => {
    api
      .post(`/entry_times`, element)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        errorHandler(error, setBackendFormErrors);
      });
  };
}

export default useStore;
