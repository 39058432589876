import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { useContext } from "react";
import { ReportContext } from "../contexts/ReportContext";
import api from "@beeldit/core/helpers/api";

function useUpdate() {
  const { element, setBackendFormErrors } = useContext(ReportContext);
  const errorHandler = useErrorHandler();
  return (onSuccess: any) => {
    if (element && element.config) {
      element.config = JSON.parse(element.config);
    }
    api
      .put(`/reports/${element.id}`, element)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        if (element && element.config) {
          element.config = JSON.stringify(element.config);
        }
        errorHandler(error, setBackendFormErrors);
      });
  };
}

export default useUpdate;
