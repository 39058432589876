import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { useContext } from "react";
import api from "@beeldit/core/helpers/api";
import { ReportContext } from "../contexts/ReportContext";

function useGet() {
  const { setElement, setShowModal } = useContext(ReportContext);
  const errorHandler = useErrorHandler();
  return (
    id: any,
    onSuccess: any = (data: any) => {
      return data;
    }
  ) => {
    api
      .get(`/reports/${id}`)
      .then((response) => {
        let data = response.data;
        data = onSuccess(response.data);
        setElement(data);
        setShowModal(true);
      })
      .catch((error) => {
        errorHandler(error);
      });
  };
}

export default useGet;
