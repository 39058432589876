import { useContext } from "react";
import { ReportContext } from "../contexts/ReportContext";

function useInitialize() {
  const {setElement, setShowModal} = useContext(ReportContext);
  return () => {
    setElement({});
    setShowModal(true);
  }
}

export default useInitialize;