import { useContext } from "react";
import { ReportContext } from "../contexts/ReportContext";

function usePrepareRemove() {
  const {setElementIdToDelete, setShowDeleteConfirmationModal} = useContext(ReportContext);
  return (id: any) => {
    setElementIdToDelete(id);        
    setShowDeleteConfirmationModal(true)
  }
}

export default usePrepareRemove;