import { User } from "@beeldit/user-and-access/users/models/User";

const adminFilterConfig = {
  type: 'object',
  properties: {
        customer_id: { 
            type: 'integer', 
        },
        report_id: { 
            type: 'integer', 
        },
        search: {
            type: 'string',
        },
        // items_per_page: { 
        //     type: 'integer', 
        //     default: 20,
        //     oneOf: [                
        //         {
        //             const: 5,
        //             title: "5 filas"
        //         },
        //         {
        //             const: 10,
        //             title: "10 filas"
        //         },
        //         {
        //             const: 20,
        //             title: "20 filas"
        //         },
        //         {
        //             const: 100,
        //             title: "100 filas"
        //         },
        //         {
        //             const: null,
        //             title: "Todas"
        //         },
        //     ]
        // },
    }
}
const customerFilterConfig = {
  type: 'object',
  properties: {
    report_id: { 
        type: 'integer', 
    },
  }
}
const getFiltersConfig: any = (user: User) => {
  let scheme: any = adminFilterConfig;
  if(user && user.role === 'customer') {
    scheme = customerFilterConfig;
  }
  return scheme;
}
export default getFiltersConfig;