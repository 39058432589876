import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import store from "@beeldit/core/services/store";
import { useContext } from "react";
import { ProjectContext } from "../contexts/ProjectContext";

function useStore() {
  const {element, setBackendFormErrors} = useContext(ProjectContext);
  const errorHandler = useErrorHandler();
  return (onSuccess: any) => {
    store('projects', element)
      .then((response: any) => {
        onSuccess(response.data);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        errorHandler(error, setBackendFormErrors)
      })
  }
}

export default useStore;