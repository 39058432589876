import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { useContext } from "react";
import api from "@beeldit/core/helpers/api";
import { ReportContext } from "../contexts/ReportContext";

function useRemove() {
  const { elementIdToDelete, setShowDeleteConfirmationModal } =
    useContext(ReportContext);
  const errorHandler = useErrorHandler();
  return (onSuccess: any) => {
    api
      .delete(`/reports/${elementIdToDelete}`)
      .then((response) => {
        setShowDeleteConfirmationModal(false);
        onSuccess();
      })
      .catch((error) => {
        errorHandler(error);
      });
  };
}

export default useRemove;
