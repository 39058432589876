import { useContext, useEffect } from 'react';
import { EntryTimeContext } from '../contexts/EntryTimeContext';
import CrudModuleFactoryTable from '@beeldit/core/components/CrudModuleFactoryTable';
import { FinalTableConfig } from '@beeldit/core/models/FinalTableConfig.interface';

interface Props {
    tableConfig: FinalTableConfig,
    tableData: any,
    onOrderByChange: any
}

function EntryTimeTable(props: Props) {
    
    const { tableData, tableConfig, onOrderByChange } = props;
    const { orderBy } = useContext(EntryTimeContext)
    
    useEffect(() => {
        tableConfig.index();
    }, []);    

    return (
        tableData ? (<CrudModuleFactoryTable config={tableConfig} data={tableData} orderBy={orderBy} onOrderByChange={onOrderByChange}></CrudModuleFactoryTable>) : (<div>Loading...</div>)
    )
}

export default EntryTimeTable;
