import { createRef, useEffect, useContext } from 'react';
import getConfig from '../config/tableConfig';
import JSONSchemaForm from '@rjsf/core';
import ReportTable from '../components/ReportTable';
import ReportForm from '../components/ReportForm';
import { Card } from 'react-bootstrap';
import useIndex from '../services/useIndex';
import useGet from '../services/useGet';
import useInitialize from '../services/useInitialize';
import useStore from '../services/useStore';
import useUpdate from '../services/useUpdate';
import usePrepareRemove from '../services/usePrepareRemove';
import useRemove from '../services/useRemove';
import { ReportContext } from '../contexts/ReportContext';
import { useNavigate } from 'react-router-dom';
import useFilters from '@beeldit/core/helpers/useFilters';
import CrudModuleFactoryTableHeaderActions from '@beeldit/core/components/CrudModuleFactoryTableHeaderActions';
import CrudModuleFactoryTablePagination from '@beeldit/core/components/CrudModuleFactoryTablePagination';
import CrudModuleFactoryModal from '@beeldit/core/components/CrudModuleFactoryModal';
import CrudModuleFactoryConfirmationModal from '@beeldit/core/components/CrudModuleFactoryConfirmationModal';
import AddQueryParamsToURL from '@beeldit/core/helpers/AddQueryParamsToURL';
import { AuthContext } from '@beeldit/user-and-access/auth/AuthContext';

function ReportsPage() {


    const { user } = useContext(AuthContext);    

    const addQueryParamsToURL: any = AddQueryParamsToURL();
    
    /** Crud functions */
    const index = useIndex();
    const indexFunction = () => index();

    const onSuccessGet: any = (data: any) => {
        data.config = data.config ? JSON.stringify(data.config) : null;
        return data;
    }
    const get = useGet();
    const getFunction = (id: any) => {
        get(id, onSuccessGet);
    };

    const initialize = useInitialize();
    const initializeFunction = () => initialize();

    /**
     * Form store success function definition
     */
     const onSuccessStore = () => {
        handleClose() // We pass the function to close the modal on success
        index(); // We pass the function to refresh the table on success
    }

    const store = useStore();
    const storeFunction = () => store(onSuccessStore);

    /**
     * Form update success function definition
     */
    const onSuccessUpdate = () => {
        handleClose() // We pass the function to close the modal on success
        index(); // We pass the function to refresh the table on success
    }

    const update = useUpdate();
    const updateFunction = () => update(onSuccessUpdate);

    const prepareRemove = usePrepareRemove();
    const prepareRemoveFunction = (id: any) => prepareRemove(id);

    const remove = useRemove();
    const onSuccessRemove = () => {
        indexFunction();
    }
    const removeFunction = () => remove(onSuccessRemove);    

    
    /** Filter loading */
    // const {filtersLoaded, setFiltersLoaded} = useContext(ReportContext);   
    const {tableFilters} = useContext(ReportContext);   
    useFilters({}, ReportContext);    

    /**Order by config */
    const {orderBy, setOrderBy} = useContext(ReportContext);

    const onOrderByChange = (orderBy: string) => {        
        setOrderBy(orderBy);
    }

    /**
     * Pagination config
     */
    const {paginationConfig, setPaginationConfig} = useContext(ReportContext);    

    const onPaginationChange = (page: number) => {
        setPaginationConfig({...paginationConfig, page: page});        
    }

    /** Index table funcion definition */
    const {tableData} = useContext(ReportContext);


    useEffect(() => {
        // if(filtersLoaded) {
            index();
        // }
    }, [tableFilters, paginationConfig, orderBy]);
    


    /** Creation and edition modal function and state definition */
    const {showModal, setShowModal} = useContext(ReportContext);
    const handleClose = () => setShowModal(false); // We pass the function to close the modal on success


    /** Creation and edition modal element definition */
    const {element, setElement} = useContext(ReportContext);    
    
    /**
     * Form Reference definition
     */
    const formRef = createRef<JSONSchemaForm>();    
    
    /**
     * Creation and edition modal configuration definition
     */
    const modalConfig = {
        context: ReportContext,
        title: element && element.id ? 'edit report' : 'create report',
        onSubmit: () => formRef.current?.submit() //We pass the function for the submit of the modal
    }
    
    /**
     * Delete Confirmation modal configuration definition
     */
     const deleteConfirmationModalConfig = {
        context: ReportContext,
        onSubmit: () => removeFunction() //We pass the function for the submit of the modal
    }

    /**
     * Form configuration definition
     */
    const formConfig = {
        formRef: formRef, // We pass the ref to the form
        onSubmit: (form: any) => {
            if(form.formData.id) { // If the element has an id, we update it
                updateFunction();
            } else { // else we create it
                storeFunction();
            }            
        },
        element: element // We pass the element to the form in order to render it in the modal form
    }

    const navigate = useNavigate();
    const redirectToReportFunction = (id: any) => {
        navigate(`/panel/report/${id}`);
    };
    
    /**
     * Table configuration definition
     */
    const tableConfig = getConfig({
        initializeFunction, // We pass the function to initialize a new element (used in the create button)
        indexFunction, // We pass the function to index the table 
        getFunction, // We pass the function to get an element (used in the edit button)
        prepareRemoveFunction,
        redirectToReportFunction: redirectToReportFunction,
    });
    

    return (
        <Card className='p-3'>
            <Card.Title className="d-flex align-items-center justify-content-between ps-3">
                <span>Reportes</span>
                <div>
                    <CrudModuleFactoryTableHeaderActions actions={tableConfig.actions}></CrudModuleFactoryTableHeaderActions>
                </div>
            </Card.Title>
            <Card.Body>
                {/* {filtersLoaded ? ( */}
                    <>
                        {/* <CrudModuleFactoryTableFilters formConfig={filterConfig} /> */}
                        <ReportTable tableData={tableData} tableConfig={tableConfig} onOrderByChange={onOrderByChange}/>
                        <CrudModuleFactoryTablePagination paginationConfig={paginationConfig} totalElements={tableData.totalElements} onPaginationChange={onPaginationChange}></CrudModuleFactoryTablePagination>
                    </>
                {/* ) : null} */}
                <CrudModuleFactoryModal modalConfig={modalConfig}>
                    <ReportForm formConfig={formConfig}/>
                </CrudModuleFactoryModal>
                <CrudModuleFactoryConfirmationModal modalConfig={deleteConfirmationModalConfig}>
                    ¿Desear eliminar el elemento?
                </CrudModuleFactoryConfirmationModal>    
            </Card.Body>            
        </Card>
    );
}

export default ReportsPage;
