import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { useContext } from "react";
import api from "@beeldit/core/helpers/api";
import { EntryTimeContext } from "../contexts/EntryTimeContext";

function useAddTime() {
  const { elementIdToAddTime, setShowAddTimeFormModal } =
    useContext(EntryTimeContext);
  const errorHandler = useErrorHandler();
  return (minutes: any, onSuccess: any) => {
    const data = {
      minutes: minutes,
    };
    api
      .put(`/entry_times/${elementIdToAddTime}/add-time`, data)
      .then((response) => {
        setShowAddTimeFormModal(false);
        onSuccess();
      })
      .catch((error) => {
        errorHandler(error);
      });
  };
}

export default useAddTime;
