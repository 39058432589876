import { useContext, useEffect } from 'react';
import { ReportContext } from '../contexts/ReportContext';
import CrudModuleFactoryTable from '@beeldit/core/components/CrudModuleFactoryTable';
import { FinalTableConfig } from '@beeldit/core/models/FinalTableConfig.interface';

interface Props {
    tableConfig: FinalTableConfig,
    tableData: any,
    onOrderByChange: any
}

function ReportTable(props: Props) {
    
    const { tableData, tableConfig, onOrderByChange } = props;
    const { orderBy } = useContext(ReportContext)
    
    return (
        tableData ? (<CrudModuleFactoryTable config={tableConfig} data={tableData} orderBy={orderBy} onOrderByChange={onOrderByChange}></CrudModuleFactoryTable>) : (<div>Loading...</div>)
    )
}



export default ReportTable;
