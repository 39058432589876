import { PaginationConfig } from "@beeldit/core/models/PaginationConfig.interface";
import { TableData } from "@beeldit/core/models/TableData.interface";
import React, { createContext, useState, useContext } from "react";

interface EntryTimeContextType {
  filtersLoaded: boolean;
  setFiltersLoaded: (filtersLoaded: boolean) => void;
  tableFilters: any;
  setTableFilters: (tableFilters: any) => void;
  paginationConfig: PaginationConfig;
  setPaginationConfig: (paginationConfig: PaginationConfig) => void;
  backendFormErrors: any;
  setBackendFormErrors: (backendFormErrors: any) => void;
  tableData: TableData;
  setTableData: (tableData: TableData) => void;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  element: any;
  setElement: (element: any) => void;
  elementIdToDelete: any;
  setElementIdToDelete: (elementIdToDelete: any) => void;
  showDeleteConfirmationModal: boolean;
  setShowDeleteConfirmationModal: (
    showDeleteConfirmationModal: boolean
  ) => void;
  orderBy: string | null;
  setOrderBy: (orderBy: string | null) => void;
  elementIdToAddTime: any;
  setElementIdToAddTime: (elementIdToAddTime: any) => void;
  showAddTimeFormModal: boolean;
  setShowAddTimeFormModal: (showAddTimeFormModal: boolean) => void;
}

export const EntryTimeContext = createContext<EntryTimeContextType>({
  filtersLoaded: false,
  setFiltersLoaded: () => {},
  tableFilters: {},
  setTableFilters: () => {},
  paginationConfig: { page: 1, elementsPerPage: 20 },
  setPaginationConfig: () => {},
  backendFormErrors: {},
  setBackendFormErrors: () => {},
  tableData: { elements: [] },
  setTableData: () => {},
  showModal: false,
  setShowModal: () => {},
  element: null,
  setElement: () => {},
  elementIdToDelete: null,
  setElementIdToDelete: () => {},
  showDeleteConfirmationModal: false,
  setShowDeleteConfirmationModal: () => {},
  orderBy: null,
  setOrderBy: () => {},
  elementIdToAddTime: null,
  setElementIdToAddTime: () => {},
  showAddTimeFormModal: false,
  setShowAddTimeFormModal: () => {},
});

interface EntryTimeProviderProps {
  children: React.ReactNode;
}

export const EntryTimeProvider: React.FC<EntryTimeProviderProps> = ({
  children,
}) => {
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [tableFilters, setTableFilters] = useState({});
  const [paginationConfig, setPaginationConfig] = useState<PaginationConfig>({
    page: 1,
    elementsPerPage: 20,
  });
  const [backendFormErrors, setBackendFormErrors] = useState(null);
  const [tableData, setTableData] = useState<TableData>({ elements: [] });
  const [showModal, setShowModal] = useState(false);
  const [element, setElement] = useState<any>(null);
  const [elementIdToDelete, setElementIdToDelete] = useState(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [elementIdToAddTime, setElementIdToAddTime] = useState(null);
  const [showAddTimeFormModal, setShowAddTimeFormModal] = useState(false);

  return (
    <EntryTimeContext.Provider
      value={{
        filtersLoaded,
        setFiltersLoaded,
        tableFilters,
        setTableFilters,
        paginationConfig,
        setPaginationConfig,
        backendFormErrors,
        setBackendFormErrors,
        tableData,
        setTableData,
        showModal,
        setShowModal,
        element,
        setElement,
        elementIdToDelete,
        setElementIdToDelete,
        showDeleteConfirmationModal,
        setShowDeleteConfirmationModal,
        orderBy,
        setOrderBy,
        elementIdToAddTime,
        setElementIdToAddTime,
        showAddTimeFormModal,
        setShowAddTimeFormModal,
      }}
    >
      {children}
    </EntryTimeContext.Provider>
  );
};
