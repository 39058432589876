import { RowAction } from "@beeldit/core/enums/RowAction.enum";
import { TableAction } from "@beeldit/core/enums/TableAction.enum";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const tableConfig: FinalTableConfig = {
      index: null,
      actions: [
        {
          name: TableAction.CREATE, 
          label: 'create',
          allowed: true,
          show: true,
          action: null,
          icon: icon({name: 'plus', style: 'solid'}),
          permissions: ['entry_times.store']
        },
        {
          name: TableAction.REFRESH, 
          label: 'refresh',
          allowed: true,
          show: true,
          action: null,
          icon: icon({name: 'refresh', style: 'solid'}),
        },

      ],
      rowActions: [
        {
          name: RowAction.EDIT, 
          label: 'edit',
          icon: icon({name: 'pencil', style: 'solid'}),
          allowed: true,
          show: true,
          action: (element: any) => {
            console.log('edit', element);
          },
          permissions: ['entry_times.update']
        },
        {
          name: RowAction.DELETE, 
          label: 'delete',
          icon: icon({name: 'trash', style: 'solid'}),
          allowed: true,
          show: true,
          action: (element: any) => { 
            console.log('delete', element);
          },
          permissions: ['entry_times.destroy']
        },
        {
          name: RowAction.COPY, 
          label: 'copy',
          icon: icon({name: 'copy', style: 'solid'}),
          allowed: true,
          show: true,
          action: (element: any) => { 
            console.log('copy', element);
          },
          permissions: ['entry_times.store']
        },
        {
          name: 'add-time', 
          label: 'add-time',
          icon: icon({name: 'plus', style: 'solid'}),
          allowed: true,
          show: true,
          action: (element: any) => { 
            console.log('copy', element);
          },
          permissions: ['entry_times.store']
        },
      ],
      rowConfiguration: {
        class: (element: any) => {
          return !element.status_id ? 'table-danger' : null;
        }
      },
      columns: [
        {
          name: 'id',
          label: '#',
          key: 'id',
          type: 'number',
          show: true,
          allowOrder: true,
        },
        {
          name: 'minutes',
          label: 'C.M',
          key: 'minutes',
          type: 'minutesToHours',
          show: true,
          allowOrder: true,
        }
        ,
        {
          name: 'no_computed_minutes',
          label: 'N.C.M',
          key: 'no_computed_minutes',
          type: 'minutesToHours',
          show: true,
          allowOrder: true,
        },
        {
          name: 'project',
          label: 'project',
          key: 'project.name',
          type: 'string',
          show: true,
          allowOrder: true,
          roles: ['admin']
        },
        {
          name: 'task',
          label: 'task',
          key: 'task',
          type: 'url',
          show: true,
          allowOrder: true,
        },
        {
          name: 'date',
          label: 'date',
          key: 'date',
          type: 'date',
          show: true,
          allowOrder: true,
        },
        {
          name: 'customer',
          label: 'customer',
          key: 'customer.name',
          type: 'string',
          show: true,
          allowOrder: true,
          roles: ['admin']
        },
        {
          name: 'status',
          label: 'status',
          key: 'status.value',
          type: 'string',
          show: true,
          allowOrder: true,
        },
        {
          name: 'created_at',
          label: 'created_at',
          key: 'created_at',
          type: 'datetime',
          show: true,
          allowOrder: true,
          roles: ['admin']
        },
        {
          name: 'updated_at',
          label: 'updated_at',
          key: 'updated_at',
          type: 'datetime',
          show: true,
          allowOrder: true,
          roles: ['admin']
        },
  
      ],
      pagination: {
        enabled: true,
        back: true
    }
}
const getConfig: any = (functions: any) => {
    const { getFunction, indexFunction, initializeFunction, prepareRemoveFunction, copyFunction, prepareAddTimeFunction } = functions;
    tableConfig.actions[0].action = () => initializeFunction();
    tableConfig.actions[1].action = () => indexFunction();
    tableConfig.index = () => indexFunction();
    tableConfig.rowActions[0].action = (id: any) => getFunction(id);
    tableConfig.rowActions[1].action = (id: any) => prepareRemoveFunction(id);
    tableConfig.rowActions[2].action = (id: any) => copyFunction(id);
    tableConfig.rowActions[3].action = (id: any) => prepareAddTimeFunction(id);
    return tableConfig;
}
export default getConfig;