import api from "@beeldit/core/helpers/api";

function index(setTableData: any, filters: any) {
    const fetchTableData = async () => {
        try {
          let filterPath = '';
          if (filters) {
            for (const [key, value] of Object.entries(filters)) {
              if(value) {
                filterPath += `&${key}=${value}`;
              }              
            }
          }
          const response = await api.get(`/customers?${filterPath}`);
          setTableData(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    fetchTableData();
}

export default index;