import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { useContext } from "react";
import api from "@beeldit/core/helpers/api";
import { EntryTimeContext } from "../contexts/EntryTimeContext";

function useIndex() {
  const {
    tableFilters,
    paginationConfig,
    orderBy,
    setTableData,
    setBackendFormErrors,
  } = useContext(EntryTimeContext);
  const errorHandler = useErrorHandler();
  return () => {
    let filterPath = "";
    if (tableFilters) {
      for (const [key, value] of Object.entries(tableFilters)) {
        if (value) {
          filterPath += `&${key}=${value}`;
        }
      }
    }
    if (paginationConfig) {
      for (const [key, value] of Object.entries(paginationConfig)) {
        if (value && key !== "total_items") {
          filterPath += `&${key}=${value}`;
        }
      }
    }
    if (orderBy) {
      filterPath += `&orderBy=${orderBy}`;
    }
    api
      .get(
        `/entry_times?orderBy=-id&relations=customer,fee,project&types=status${filterPath}`
      )
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        errorHandler(error, setBackendFormErrors);
      });
  };
}

export default useIndex;
