import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/custom.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Panel from './main/components/Panel';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faStopwatch, faSitemap, faList, faTag, faBarsProgress, faChartLine } from '@fortawesome/free-solid-svg-icons'
import { AuthProvider } from '@beeldit/user-and-access/auth/AuthContext';
import Login from '@beeldit/user-and-access/auth/pages/Login';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <Main />
        </AuthProvider>
      </BrowserRouter>    
    </React.StrictMode>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


function Main() {

  library.add(faStopwatch, faSitemap, faList, faTag, faBarsProgress, faChartLine)

  return (
      <div className='App'>
        <main>
          <Routes>
            <Route path="/panel/*" element={<Panel />} />            
            <Route path="/login" element={<Login />} />
          </Routes>
        </main>
      </div>      
  );
}