import { useContext } from "react";
import { EntryTimeContext } from "../contexts/EntryTimeContext";

function usePrepareAddTime() {
  const {setElementIdToAddTime, setShowAddTimeFormModal} = useContext(EntryTimeContext);
  return (id: any) => {
    setElementIdToAddTime(id);        
    setShowAddTimeFormModal(true)
  }
}

export default usePrepareAddTime;