import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { useContext } from "react";
import api from "@beeldit/core/helpers/api";
import { ReportContext } from "../contexts/ReportContext";

function useStore() {
  const { element, setBackendFormErrors } = useContext(ReportContext);
  const errorHandler = useErrorHandler();
  return (onSuccess: any) => {
    if (element && element.config) {
      element.config = JSON.parse(element.config);
    }
    api
      .post(`/reports`, element)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        if (element && element.config) {
          element.config = JSON.stringify(element.config);
        }
        console.error("Error fetching data:", error);
        errorHandler(error, setBackendFormErrors);
      });
  };
}

export default useStore;
