import { RJSFSchema, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import JSONSchemaForm from '@rjsf/core';
import { EntryTimeContext } from '../contexts/EntryTimeContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

interface Prop {
    formConfig: {
        formRef: any,
        onSubmit: any,
    },
}

function EntryTimeAddTimeForm(props: Prop) {

    const {formRef, onSubmit} = props.formConfig;    
    const {element, setElement, backendFormErrors} = useContext(EntryTimeContext);

    const { t } = useTranslation();
  
    const translations = {
        minutes: t('minutes'),
      }

    const schema: RJSFSchema = {
      type: 'object',
      // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
      properties: {
        minutes: { type: 'integer', title: translations.minutes, default: 0 },
      },      
    };
    const uiSchema: UiSchema = {
      "ui:submitButtonOptions": {
        "props": {
          "disabled": false,
          "className": "btn btn-info"
        },
        "norender": true,
      }
    };
    const handleChange = (type: any) => { /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
      // Actualizar el estado solo si no hay errores de validación
      setElement(type.formData);
      return console.log.bind(console, type)
    };
    const log = (type: any) => {
        return console.log.bind(console, type)
      };

    return (
      <JSONSchemaForm
        noHtml5Validate
        showErrorList={false}
        ref={formRef}
        schema={schema}
        uiSchema={uiSchema}
        formData={element}
        validator={validator}
        onChange={handleChange}
        onSubmit={onSubmit}
        onError={log('errors')}
        extraErrors={backendFormErrors}
      />            
    )
}

export default EntryTimeAddTimeForm;
