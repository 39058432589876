import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { useContext } from "react";
import api from "@beeldit/core/helpers/api";
import { EntryTimeContext } from "../contexts/EntryTimeContext";

function useRemove() {
  const { elementIdToDelete, setShowDeleteConfirmationModal } =
    useContext(EntryTimeContext);
  const errorHandler = useErrorHandler();
  return (onSuccess: any) => {
    api
      .delete(`/entry_times/${elementIdToDelete}`)
      .then((response) => {
        setShowDeleteConfirmationModal(false);
        onSuccess();
      })
      .catch((error) => {
        errorHandler(error);
      });
  };
}

export default useRemove;
