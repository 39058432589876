import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { useContext } from "react";
import api from "@beeldit/core/helpers/api";
import { EntryTimeContext } from "../contexts/EntryTimeContext";

function useUpdate() {
  const { element, setBackendFormErrors } = useContext(EntryTimeContext);
  const errorHandler = useErrorHandler();

  return (onSuccess: any) => {
    api
      .put(`/entry_times/${element.id}`, element)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        errorHandler(error, setBackendFormErrors);
      });
  };
}

export default useUpdate;
