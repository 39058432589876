import Placeholder from "react-select/dist/declarations/src/components/Placeholder";
import CustomerSelector from "../../customers/components/CustomerSelector";
import ReportSelector from "../../reports/components/ReportSelector";
import { User } from "@beeldit/user-and-access/users/models/User";

const adminFilterUIConfig = {
    uiScheme: {
        classNames: "filters-container",
        customer_id: {
            "ui:widget": "customer-selector",
            "ui:options": {
                label: false, // Oculta el título del campo
            }
        },
        report_id: {
            "ui:widget": "report-selector",
            "ui:options": {
                label: false // Oculta el título del campo
            }
        },
        search: {
            "ui:options": {
                label: false, // Oculta el título del campo
                placeholder: "Buscar..."
            }
        },
        // items_per_page: {
        //     "ui:options": {
        //         label: false, // Oculta el título del campo
        //     }
        // }
    },
    widgets: {
        'report-selector': ReportSelector,
        'customer-selector': CustomerSelector
    }
}
const getFilterUIConfig: any = (user: User) => {
    return adminFilterUIConfig;
}
export default getFilterUIConfig;