import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ReportProvider } from '@main/reports/contexts/ReportContext';
import { useContext, useState } from 'react';
import { Breadcrumb, Navbar } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Route, Routes } from 'react-router-dom';
import { EntryTimeProvider } from '../../entry-time/contexts/EntryTimeContext';
import EntryTimesPage from '../../entry-time/pages/EntryTimesPage';
import ReportPage from '../../reports/pages/ReportPage';
import ReportsPage from '../../reports/pages/ReportsPage';
import AppNavbar from './AppNavbar';
import Sidebar from './Sidebar';
import { AuthContext } from '@beeldit/user-and-access/auth/AuthContext';
import ProjectPage from '@main/projects/pages/ProjectPage';
import { ProjectProvider } from '@main/projects/contexts/ProjectContext';

function Panel() {
  const { tokenLoaded, user} = useContext(AuthContext);

  if (!tokenLoaded || !user) {
    return <div>Loading...</div>;
  }

  const items = [
    { label: 'Entry times', to: '/panel/entry-times', icon: icon({name: 'stopwatch', style: 'solid'}) },
    { label: 'Reports', to: '/panel/reports', icon: icon({name: 'chart-line', style: 'solid'}) },
    { label: 'Projects', to: '/panel/projects', icon: icon({name: 'cubes', style: 'solid'}) },
  ];
  

  return (
    <div>
      <AppNavbar items={items}/>
      <div className="container-fluid">
        <div className="row">
          <Sidebar items={items} />
          <div className="page-content p-4">            
            <Routes>
              <Route path="/entry-times" element={<EntryTimeProvider><EntryTimesPage/></EntryTimeProvider>} />
              <Route path="/reports" element={<ReportProvider><ReportsPage /></ReportProvider>} />
              <Route path="/report/:id" element={<ReportProvider><ReportPage /></ReportProvider>} />
              <Route path="/projects" element={<ProjectProvider><ProjectPage /></ProjectProvider>} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Panel;
